<template>
  <div>
    <vue-dropzone
      ref="myVueDropzone"
      id="dropzone"
      :useCustomSlot="true"
      :options="dropzoneOptions"
      @vdropzone-success="dropzoneSuccees"
      @vdropzone-sending="sendingEvent"
      @vdropzone-drag-over="drag = true"
      @vdropzone-drag-leave="drag = false"
    >
      <!-- style="border: 1px dashed; height: 250px" -->
      <div :class="drag ? 'dropzone-drag-content'  : 'dropzone-custom-content'">
        <div>
          <h3 class="dropzone-custom-title">Arrastra tu estructura de carpetas aqui!</h3>
          <!-- <div class="subtitle">
            ...or click to select a file from your computer
          </div> -->
        </div>
      </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      myFiles: [],
      drag: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
        clickable: false
      },
    };
  },
  methods: {
    startDragHandler(event) {
      console.log(event)
    },
    dropzoneSuccees(file, dos) {
      // console.log(file, dos)
    },
    sendingEvent(file, xhr, formData) {
      // console.log(file, xhr, formData)
      // formData.append('paramName', 'some value or other');
    },
  },
  async mounted() {
    const dropzoneElement = document.getElementById("dropzone");

    dropzoneElement.addEventListener("drop", (event) => {
      event.preventDefault();
      handleDrop(Array.from(event.dataTransfer.items));
    });

    const root = [];
    const filesArray = [];

    const handleDrop = async (items) => {
      for (const item of items) {
        const entry = item.webkitGetAsEntry();
        if (entry) {
          await traverseFileTree(entry);
        }
      }

      updateFoldersWithFiles(root, filesArray)
  
      this.$emit('uploadFolders', root)
      this.$emit('uploadFiles', filesArray)
    };

    function updateFoldersWithFiles(folders, files) {
      // Helper function to find the folder path recursively
      function findAndInsert(folder, pathParts, file) {
        if (pathParts.length === 0) {
          if (!folder.files) {
            folder.files = [];
          }
          folder.files.push({ name: file.name });
          return;
        }

        const nextFolderName = pathParts.shift();
        for (let child of folder.childs) {
          if (child.name === nextFolderName) {
            findAndInsert(child, pathParts, file);
            return;
          }
        }
      }

      // Iterate over each file and its path
      files.forEach(({ file, path }) => {
        const pathParts = path.split("/").filter(Boolean);
        if (pathParts.length > 0) {
          const rootFolderName = pathParts.shift();
          for (let folder of folders) {
            if (folder.name === rootFolderName) {
              findAndInsert(folder, pathParts, file);
              break;
            }
          }
        }
      });

      return folders;
    }

    const traverseFileTree = (item, path = "") => {
      return new Promise(async (resolve) => {
        if (item.isDirectory) {
          const dirReader = item.createReader();
          dirReader.readEntries(async (entries) => {
            addToHierarchy(root, `${path}/${item.name}`);
            await Promise.all(
              entries.map((entry) =>
                traverseFileTree(entry, `${path}/${item.name}`)
              )
            );
            resolve();
          });
        } else if (item.isFile) {
          item.file((file) => {
            // VALIDATION FILES HIDDEN
            if(file.size > 0 && !file.name.startsWith('.')){
              filesArray.push({ path: `${path}`, file });
            }
            resolve();
          });
        } else {
          resolve();
        }
      });
    };

    const addToHierarchy = (root, path) => {
      const parts = path.split("/").filter(Boolean);
      let currentLevel = root;

      parts.forEach((part) => {
        let existing = currentLevel.find(
          (item) => item.name === part && !item.isFile
        );
        if (!existing) {
          existing = { name: part, childs: [] };
          currentLevel.push(existing);
        }
        currentLevel = existing.childs;
      });
    };
  },
};
</script>

<style lang="scss">
.dropzone-custom-content {
  border: 1px dashed var(--primary);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  text-align: center;
}

.dropzone-drag-content {
  border: 2px dashed var(--primary);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  text-align: center;
  opacity: 40%;
}

.dropzone-custom-title {
  margin-top: 0;
  color: var(--primary)
}

// .subtitle {
//   color: #314b5f;
// }

.filepond--list {
  display: none;
}
#dropzone .dz-preview {
  display: none;
}
</style>
